footer {
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
    .footer-bg-1 {
        position: absolute;
        left: -2.25rem;
        bottom: -4.75rem;
        opacity: 0.3;
        max-width: min(100%, 36rem);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .footer-bg-2 {
        max-width: min(100%, 24rem);
        position: absolute;
        right: 22.5%;
        bottom: 0;
        text-align: center;
        @media only screen and (max-width: 1200px) {
            right: 0;
        }
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .footer-bg-3 {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: min(100%, 34rem);
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
    .footer-bg-4 {
        position: absolute;
        right: 0;
        bottom: 0;
        max-width: min(100%, 34rem);
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
    .footer-links-wrapper {
        padding: 4.6875rem 0 0.9375rem 0;
        display: flex;
        flex-direction: row;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            padding: 3.5rem 0 0 0;
        }
        .logo-div {
            z-index: 10;
            flex-grow: 1;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            flex-wrap: wrap;
            .footer-logo {
                max-width: min(100%, 9.375rem);
                max-height: min(100%, 2.1875rem);
                margin-bottom: 0.5rem;
            }
            .footer-lang-select {
                margin-top: 3.375rem;
                margin-bottom: 1.875rem;
            }
            @media only screen and (max-width: 768px) {
                .footer-lang-select {
                    margin-top: 0;
                }
                flex-direction: row;
                justify-content: space-between;
                align-items: flex-start;
            }
        }
        .links-container{
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            flex: 1;

        }
        .links-div {
            z-index: 10;
            display: flex;
            flex-direction: column;
            margin-inline-start: 6.25rem;
            @media only screen and (max-width: 1200px) {
                margin-inline-start: 1.5625rem;
            }
            @media only screen and (max-width: 768px) {
                margin-inline-start: 0;
                // align-items: center;
                // justify-content: center;
                margin-top: 2rem;
            }
            h5 {
                margin: 0.625rem 1.5rem;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 600;
                line-height: 160%; /* 1.8rem */
                letter-spacing: 0.0112rem;
                @media only screen and (max-width: 768px) {
                    margin: 0.625rem 0;
                }
            }
            a {
                margin: 0.625rem 1.5rem;
                color: var(--text-alt-color-1);
                text-decoration: none;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 140%; /* 1.4875rem */
                &:hover {
                    color: var(--primary-color);
                    transition: 0.25s all ease-in-out;
                }
                @media only screen and (max-width: 768px) {
                    margin: 0.625rem 0;
                }
            }
        }
    }
    .social-bar {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: center;
        border-top: 0.0625rem solid var(--divider-color);
        margin-top: 2rem;
        margin-bottom: 1rem;
        padding-top: 2.5rem;
        @media only screen and (max-width: 768px) {
            margin-bottom: 0;
            padding-top: 1rem;
            justify-content: center;
        }
        .link-wrapper {
            z-index: 10;
            display: flex;
            flex-direction: row;
            align-items: center;
            flex-wrap: wrap;
            @media only screen and (max-width: 768px) {
                margin-inline-start: 0.75rem;
            }
        }
        .vertical-divider {
            width: 0.0625rem;
            height: 2rem;
            margin: 0 1.5rem;
            border-right: 0.0625rem solid var(--divider-color);
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
        a {
            color: var(--text-alt-color-1);
            text-decoration: none;
            font-size: 1.0625rem;
            font-style: normal;
            font-weight: 400;
            line-height: 140%; /* 1.4875rem */
            &:hover {
                color: var(--primary-color);
                transition: 0.25s all ease-in-out;
            }
            @media only screen and (max-width: 768px) {
                margin-bottom: 0.9375rem;
            }
        }
        .spacer {
            margin: auto;
            flex: 1;
            @media only screen and (max-width: 768px) {
                margin-bottom: 1.25rem;
            }
            @media only screen and (max-width: 305px) {
                display: none;
            }
        }
        a.link-icon {
            margin-inline-start: 2rem;
            @media only screen and (max-width: 768px) {
                margin-inline-start: 0.5rem;
                margin-inline-end: 0.5rem;
                svg {
                    height: 1.5rem !important;
                    width: 1.5rem !important;
                }
            }
            &:hover {
                color: var(--primary-color);
                svg path {
                    transition: 0.25s all ease-in-out;
                    fill: var(--primary-color) !important;
                }
            }
        }
    }
}
body[dir="rtl"] {
    footer {
        .footer-bg-1 {
            transform: scaleX(-1);
            left: auto;
            right: -2.25rem;
        }
        .footer-bg-2 {
            transform: scaleX(-1);
            right: auto;
            left: 22.5%;
            @media only screen and (max-width: 1200px) {
                left: 0;
            }
        }
        .footer-bg-3 {
            transform: scaleX(-1);
            left: auto;
            right: 0;
        }
        .footer-bg-4 {
            transform: scaleX(-1);
            right: auto;
            left: 0;
        }
    }
}
