.white-nav-bar{
    box-shadow: 0px 4px 24px 0px rgba(0, 0, 0, 0.05) !important;
    width: inherit;
    margin: 0;
    padding: 2.5rem 11.666rem;
    position: relative;
    z-index: +1;
    @media only screen and (max-width: 768px) {
        position: static;
        box-shadow: none;
        padding: 1.125rem 1.5rem;
        border-bottom: 1px solid rgba(246, 246, 246, 1);
        .menu-btn {
            cursor: pointer;
            height: 4rem;
            width: 4rem;
        }
    }
    
}
nav {
    z-index: 100;
    margin: 1.5rem 0 0 0;
    padding-bottom: 1.09rem;
    width: 100%;
    flex-grow: 1;
    display: flex;
    align-items: center;
    .logo {
        display: inline-block;
        cursor: pointer;
        text-decoration: none;
        line-height: 0;
        padding: 0;
        img {
            max-width: 10rem;
        }
        .short-logo{
            width: 34.3px;
            height: 33px;
            object-fit: cover;
        }
    }
    .nav-list {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 0.375rem 0 0 0;
        padding: 0;
        padding-inline-start: 1.875rem;
        list-style: none;
        color: var(--text-color);
        width: auto;
        background: none;
        border: none;
        li {
            padding: 0 !important;
            .ant-menu-title-content {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.1rem;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                color: var(--text-color);
                padding: 0.625rem 1.5rem;
                cursor: pointer;
                svg {
                    margin-inline-start: 0.25rem;
                    height: 1.5rem;
                    width: 1.5rem;
                }
                svg path {
                    stroke: var(--text-color);
                }
                @media only screen and (max-width: 900px) {
                    padding: 0.5rem;
                }
            }
        }
        &.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after {
            border: none;
        }
    }
    .spacer {
        flex-grow: 1;
        .page-title{
            font-family: Noto Sans;
            font-size: 15px;
            font-weight: 600;
            line-height: 20.43px;
            text-align: center;
        }
    }
    .lang-select {
        color: var(--background-color);
        margin-inline-end: 3.75rem;
        @media only screen and (max-width: 900px) {
            margin-inline-end: 1.5rem;
        }
    }
    .sign-up-btn {
        color: var(--text-color);
        text-decoration: none;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 1.4875rem */
        margin-inline-end: 1.5rem;
        display: inline-flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        svg {
            path {
                stroke: var(--text-color) !important;
            }
            height: 1.5rem;
            width: 1.5rem;
            margin-inline-start: 0.375rem;
        }
    }
    .WhiteBar-login-btn {
        color: #fff;
        background: rgba(255, 152, 0, 1);
        padding: 0.625rem 1.5rem;
        border: 1px solid #fff;
        border-radius: 1.5rem;
        text-decoration: none;
        font-size: 1.0625rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 1.4875rem */
    }
    .hide-in-mobile {
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .show-in-mobile {
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
}
.mobile-drawer-lang-selector{
    padding: 0.333rem  1.9rem 0.333rem 2.1rem;
    .mobile-lang-btn{
        box-shadow: none !important;
        color: var(--secondary-color);
        border: 1px solid var(--secondary-color);
        border-radius: 2rem;
        font-size: 0.9rem;
    }
    .mobile-lang-btn.active-btn{
        background: var(--secondary-color);
        color: #fff;
    }
}
.main-menu-drawer {
    .ant-drawer-body {
        padding: 0.5rem;
    }
}
