.lang-select {
    label {
        display: flex;
        align-items: center;
        flex-direction: row;
    }

    .select-icon {
        width: 1.875rem !important;
        height: 1.875rem !important;
        padding-inline-end: 0.875rem;
    }

    .lang-menu {
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: start;
        margin: 0;
        padding: 0;
        min-width: 80px;
        padding-inline-end: 0.1rem;
        list-style: none;
        color: var(--text-color);
        width: auto;
        background: none;
        border: none;
        li {
            padding: 0 !important;
            .ant-menu-title-content {
                display: flex;
                justify-content: center;
                align-items: center;
                font-size: 1.0625rem;
                font-style: normal;
                font-weight: 500;
                line-height: 140%; /* 1.4875rem */
                color: var(--text-color);
                padding: 0;
                cursor: pointer;
                svg.arrow {
                    margin-inline-start: 0.25rem;
                    height: 1.5rem;
                    width: 1.5rem;
                }
                svg.arrow path {
                    stroke: var(--text-color);
                }
            }
        }
        &.ant-menu-light.ant-menu-horizontal > .ant-menu-submenu::after {
            border: none;
        }
        &.white {
            li {
                .ant-menu-title-content {
                    color: var(--background-color);
                }
                svg.arrow path {
                    stroke: var(--background-color);
                }
            }
        }
    }
}
.ant-menu-item-selected {
    background-color: #e0e0e0;
}
