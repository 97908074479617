.onboarding-mainContainer{
    min-height: 100%;
    background: #f0f0f0;
    font-family: "Noto Sans", sans-serif !important;
}
.onboarding-mainCol{
    background: #fff;
    padding:0 1rem ;
}
/* -----------------------Smart Banner------------------------------------- */
.smartBanner{
    padding: 1rem  1rem;
    background-color: #f2f1f6;
    /* height: 6.25rem; */
    /* border-bottom: 1px solid #8c8c8c; */
    /* border-bottom: 1px solid #595959; */
    
}
.smartBanner .bannerText{
    font-weight: bold;
    font-family: "Noto Sans", sans-serif !important;
    /* font-size: 1rem; */
}
[dir="rtl"] .smartBanner .bannerText{
    font-size: 1.3rem;
    margin-bottom: 0;
}
.smartBanner-cancelBtn{
    border-radius: 1rem;
}
.smartBanner-downloadBtn{
    /* color: #fff;
    background: #fff; */
    border-radius: 1rem;
}
.smartBannerImage{
    height: 1.5rem;
    object-fit: cover;
    /* height: 5rem;
    width: 5rem; */
}
/* ----------------header section--------------------- */
.onboarding-headerSection{
    padding-top: 1rem;
}
.onboarding-thriveLogo{
    height: 2.1rem;
}
.onboarding-langButton{
    border: 1px solid rgba(29, 43, 83, 1);
    border-radius: 1.25rem;
    font-weight: 400;
    padding: 0 1.5rem !important;
    box-shadow: none;
    border: 1px solid rgba(29, 43, 83, 1) !important;
    color: rgba(69, 26, 107, 1);
    font-weight: 400 !important;
    font-size: 0.75;
}
/* -----------------------CongratsSec------------ */
.onboarding-congratsSection{
    margin-top: 5rem;
}
.onboarding-congratsSection .title{
    font-size: 2.125rem;
    margin-bottom: 0px;
}
.onboarding-celebrateImage{
    height: 12.3rem;
    margin-top: 3.75rem;
    object-fit: contain;
}
.congratsSecondContainer{
    margin-top: 3.75rem;
}
.congratsSecondContainer .text{
    font-size: 1.375rem;
    font-weight: 600;
}
.congratsSecondContainer .text{
    font-size: 1.375rem;
    font-weight: 600;
}
.congratsThirdContainer{
    margin-top: 2.6875rem;
}
/* ----------------step-------------------- */
.onboarding-firstStep{
    margin-top: 7.5rem;
}
.onboarding-stepSection{
    margin-top: 18.75rem;
}
.stepTitle{
    font-size: 2.125em;
    font-weight: 600;
    line-height: 2.88rem;
    text-align: center;
    margin-bottom: 0.75rem !important;
}
.stepSubtitle{
    font-size: 15px;
    font-weight: 600;
    line-height: 20.43px;
    text-align: center;
}
.subStepImageContainer{
    margin-top: 1.875rem;
}
.subStepDescription{
    font-size: 0.9375rem;
    font-weight: 400;
    line-height: 1.26rem;
    margin-top: 1.875rem !important;
}
[dir="rtl"] .subStepDescription{
    font-size: 1.1rem;
}
.onboarding-subStepSection{
    margin-bottom: 5rem;
}
/* -----------------FAQs--------------------- */
.onboarding-faqSection{
    margin-top: 18.75rem;
}
.onboarding-faqSection .title{
    font-size: 2.125rem;
    font-weight: 600;
    line-height: 2.88rem;
    margin-bottom: 0 !important;
}
.onboarding-questionItem{
    margin-top: 1.875rem;
}
.questionTitle{
    font-size: 12px;
    font-weight: 700;
    line-height: 16.34px;
}
[dir="rtl"] .questionTitle{
    font-size: 1.1rem;
    line-height: 1.15rem;
}
.questionAnswer{
    font-size: 12px;
    font-weight: 400;
    line-height: 16.34px;
    color: rgba(0, 0, 0, 0.8);
}
[dir="rtl"] .questionAnswer{
    font-size: 1.1rem;
    line-height: 1.13rem;
}
.questionDivider{
    margin-top: 1rem;
    margin-bottom: 1.875rem;
    border: 3px solid rgba(0, 0, 0, 0.2);
    border-radius: 1.5px;
}
/* ---------------helpSection----------- */
.onboarding-helpSection{
    margin-top: 18.75rem;
    margin-bottom: 16.5rem;
}
.onboarding-helpSection .title{
    font-size: 34px;
    font-weight: 600;
    line-height: 46.31px;
    margin-bottom: 1.875rem !important;
}
.onboarding-helpSection .subTitle{
    font-size: 15px;
    font-weight: 400;
    line-height: 20.43px; 
    color: rgba(0, 0, 0, 0.8);   
}
[dir="rtl"] .onboarding-helpSection .subTitle{
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.3rem; 
}
.onboarding-contactUsButton{
    border-radius: 1rem;
    border: 1px solid rgba(69, 26, 107, 1);
    border-image-source: linear-gradient(0deg, #451A6B, #451A6B),
    linear-gradient(0deg, rgba(69, 26, 107, 0.8), rgba(69, 26, 107, 0.8)),
    linear-gradient(0deg, rgba(69, 26, 107, 0.6), rgba(69, 26, 107, 0.6)),
    linear-gradient(0deg, rgba(69, 26, 107, 0.4), rgba(69, 26, 107, 0.4)),
    linear-gradient(0deg, rgba(69, 26, 107, 0.2), rgba(69, 26, 107, 0.2)) !important;
    font-size: 12px;
    font-weight: 600;
    line-height: 14.98px;
    color: rgba(69, 26, 107, 1);
}
/* ---------------------------------Common--------------------------------------- */
.textCenterAlign{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-wrap: pretty;
    word-wrap: normal;
}
.onBoarding-margin-1{
    margin-top: 1rem;
}
.onBoarding-margin-2{
    margin-top: 2rem;
}
.onBoarding-margin-4{
    margin-top: 4rem;
}
.onBoarding-margin-6{
    margin-top: 6rem;
}
.onBoarding-margin-b-2{
    margin-bottom: 2rem;
}
.onBoarding-margin-8{
    margin-top: 8rem;
}
.centerBlockContent{
    text-align: center;
}
.ParagraphFontSize1rem{
    font-size: 1rem;
}
.secondaryText{
    font-size: 15px;
    font-weight: 400;
    line-height: 20.43px;
    text-align: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    text-wrap: pretty;
    word-wrap: normal;
}
[dir="rtl"] .secondaryText{
    font-size: 1.15rem;
}
.hide{
    display: none !important;
}
.bg-hide{
    display: none !important;
}
@media only screen and (max-width: 361px) {
    .smartBanner .bannerText{
        font-size: 0.7rem;
    }
}
@media only screen and (max-width: 500px) {
    .bg-hide{
        display: flex !important;
    }
}