#notfound {
    position: relative;
    height: 100vh;

    .notfound {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        // max-width: 28.75rem;
        width: 100%;
        text-align: center;
        line-height: 1.4;

        .notfound-404 {
            position: relative;
            width: 13.5rem;
            height: 13.5rem;
            margin: 0 auto 4.5rem;
        }
        .notfound-404 > div:first-child {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: var(--primary-color);
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
            border: 0.625rem dashed #000;
            border-radius: 0.625rem;
        }
        .notfound-404 > div:first-child:before {
            content: "";
            position: absolute;
            left: -0.625rem;
            right: -0.625rem;
            bottom: -0.625rem;
            top: -0.625rem;
            -webkit-box-shadow: 0 0 0 0.625rem rgba(0, 0, 0, 0.1) inset;
            box-shadow: 0 0 0 0.625rem rgba(0, 0, 0, 0.1) inset;
            border-radius: 0.625rem;
        }
        .notfound-404 h1 {
            font-family: cabin, sans-serif;
            color: #000;
            font-weight: 700;
            margin: 0;
            font-size: 6rem;
            position: absolute;
            top: 50%;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            left: 50%;
            text-align: center;
            height: 3rem;
            line-height: 3rem;
        }
        h2 {
            font-family: "IBM Plex Sans Arabic", sans-serif;
            font-size: 2.25rem;
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: normal;
        }
        p {
            font-size: 1rem;
            color: #000;
            font-weight: 400;
        }
        a {
            display: inline-block;
            padding: 0.85rem 1.75rem;
            background-color: var(--secondary-color);
            border: none;
            border-radius: 2.5rem;
            color: #fff;
            font-size: 1.25rem;
            font-weight: 700;
            text-transform: uppercase;
            text-decoration: none;
            -webkit-transition: 0.2s all;
            transition: 0.2s all;
            border: solid 2px var(--secondary-color);
            margin: 0.25rem;
            &:hover {
                background-color: var(--secondary-color-hovered);
            }
            &.outlined {
                color: var(--secondary-color);
                background: transparent;
            }
        }
    }
}
