.hero-header-section {
    width: inherit;
    overflow: hidden;
    border-radius: 0 0 3.75rem 3.75rem;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0 16.25rem 0   ;
    background-color: var(--background-color);
    @media only screen and (max-width: 768px) {
        padding: 0rem 0.5rem 11.6668rem 0.5rem;
    }
    .gradient-div {
        min-width: 129.625rem;
        width: 250%;
        min-height: 75rem;
        transform: rotate(-7.797deg);
        flex-shrink: 0;
        background: linear-gradient(180deg, #ffb600 0%, #ba62fb 46.5%, #1d2b53 100%);
        position: absolute;
        top: -53rem;
        left: -50%;
        animation: Animation 10s ease-in-out infinite;
        background-size: 200% 200%;
        @media only screen and (max-width: 1200px) {
            top: -48rem;
        }
        @media only screen and (max-width: 376px) {
            top: -48rem;
        }
        @media only screen and (max-width: 360px) {
            top: -44rem;
        }
        @media only screen and (max-width: 315px) {
            top: -35rem;
        }
    }
    @-webkit-keyframes Animation {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
    @-moz-keyframes Animation {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
    @keyframes Animation {
        0% {
            background-position: 10% 0%;
        }
        50% {
            background-position: 91% 100%;
        }
        100% {
            background-position: 10% 0%;
        }
    }
    .screen-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .main-section {
        width: 100%;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        .title {
            color: var(--background-color);
            font-size: 3.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 5.6rem */
            letter-spacing: 0.035rem;
            max-width: 33.75rem;
            margin: 3.75rem 0 9.6875rem 0;
            margin-inline-end: auto;
            @media only screen and (max-width: 1200px) {
                margin-bottom: 8rem;
            }
        }
        .text {
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 180%; /* 2.7rem */
            letter-spacing: 0.015rem;
            max-width: 47rem;
            @media only screen and (max-width: 1200px) {
                max-width: none;
            }
        }
        .control-btn{
            margin-top: 3.75rem ;
        }
        // center text
        @media only screen and (max-width: 768px) {
            .text,.title{
                display: flex;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                text-wrap: pretty;
                word-wrap: normal;
            }
            .text{
                padding-top: 4.75rem;
            }
        }
        .choose-btn {
            cursor: pointer;
            padding: 1rem 2.5rem;
            margin: 0 0 0.625rem 0;
            border: none;
            border-radius: 2.5rem;
            background: var(--primary-color);
            color: var(--background-color);
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 1.925rem */
            display: inline-block;
            &:hover {
                background-color: var(--primary-color-hovered);
                transition: 0.25s background-color ease-in-out;
            }
            @media only screen and (max-width: 768px) {
                margin: 1.25rem 0;
                width: 100%;
            }
        }
        .explore-btn {
            margin: 0 0 0 0;
            margin-inline-start: 1.875rem;
            color: var(--primary-color);
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
                height: 1.5rem;
                width: 1.5rem;
                margin-inline-start: 0.375rem;
            }
        }
        .hero-spacer {
            min-width: 55rem;
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
    }
    .hero-img {
        min-width: 53.1875rem;
        min-height: 41.875rem;
        position: absolute;
        right: 0;
        top: 9rem;
        flex-shrink: 0;
        border-radius: 21.25rem 1rem 1rem 0rem;
        box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25);
        @media only screen and (max-width: 1200px) {
            display: none;
        }
    }
    .mobile-img {
        position: absolute;
        z-index: 2;
        border-radius: 1.5625rem;
        min-width: 18.75rem;
        min-height: 41.375rem;
        box-shadow: 0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.5);
        top: 11.25rem;
        right: 43.75rem;
        background-repeat: no-repeat !important;
        background-size: 100% 98% !important;
        background-position-x: center !important;
        @media only screen and (max-width: 1200px) {
            display: none;
        }
    }
    .animation-wrapper {
        z-index: 10;
        min-width: 62.5rem;
        min-height: 52.625rem;
        position: absolute;
        right: 0;
        top: 0;
    }
}
body[dir="rtl"] {
    .hero-header-section {
        .gradient-div {
            transform: rotate(7.797deg);
            left: auto;
            right: -50%;
            top: -57rem;
            @media only screen and (max-width: 1200px) {
                top: -50rem;
            }
            @media only screen and (max-width: 1025px) {
                top: -55rem;
            }
            @media only screen and (max-width: 387px) {
                top: -50rem;
            }
            @media only screen and (max-width: 360px) {
                top: -48rem;
            }
        }
        .hero-img {
            transform: scaleX(-1);
            right: auto;
            left: 0;
        }
        .mobile-img {
            right: auto;
            left: 41.25rem;
            background-repeat: no-repeat !important;
            background-size: 100% 98% !important;
            background-position-x: center !important;
        }
        .animation-wrapper {
            right: auto;
            left: 0;
        }
        .sign-up-btn,
        .explore-btn {
            svg {
                transform: scaleX(-1);
            }
        }
        @media only screen and (max-width: 768px) {
            .text {
                padding-top: 0px;
            }
        }
        @media only screen and (max-width: 360px) {
            .text{
                padding-top: 4rem;
            }
        }
    }
}
.sponsors-icons-section {
    border-radius: 3.75rem 3.75rem 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0rem 0 10rem 0;
    background-color: var(--background-color);
    @media only screen and (max-width: 768px) {
        //padding: 1.25rem 0.5rem 5rem 0.5rem;
        padding: 0rem 0.5rem 16.6668rem 0.5rem;
    }
    .trusted {
        color: #000;
        text-align: center;
        font-size: 1.5rem;
        font-style: normal;
        font-weight: 600;
        line-height: 160%; /* 2.4rem */
        letter-spacing: 0.015rem;
        margin: 2.5rem 0;
    }
    .screen-wrapper {
        display: flex;
        flex-wrap: wrap;
        flex: 1;
        flex-direction: row-reverse;
        justify-content: center;
        align-content: center;
        align-items: center;
        // grid-template-columns: repeat(5, minmax(0, 1fr));
        margin: auto;
        .sponsor-container{
            display: block;
            flex: 0 0 20%;
        }
        .sponsor {
            display: flex;
            // min-width: 30%;
            justify-content: center;
            align-items: center;
            min-height: 9.125rem;
            padding: 0 0.5rem;
            border-inline-end: #efeff3 0.0625rem dashed;
            &:nth-child(4n) {
                border-inline-end: #efeff3 0.0625rem dashed;
            }
            img {
                max-width: 100%;
                max-height: 4.25rem;
                padding: 0 0.5rem;
            }
            @media only screen and (max-width: 1200px) {
                &:nth-child(2n) {
                    border: none;
                }
            }
            @media only screen and (max-width: 768px) {
                min-height: 8rem;
                img {
                    max-height: 2.5rem;
                }
            }
        }
        @media only screen and (max-width: 1200px) {
            grid-template-columns: repeat(2, minmax(0, 1fr));
            row-gap: 2rem;
            .sponsor-container{
                display: block;
                flex: 0 0 50%;
            }
            .sponsor.withoutBorderInMobile{
                border: none;
            }
        }
    }
    
}
.unlock-potential-section {
    border-radius: 3.75rem 3.75rem 0 0;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 0;
    background-color: var(--background-alt-color-1);
    @media only screen and (max-width: 768px) {
        padding: 0 0.5rem;
    }
    .screen-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    .hide-in-mobile {
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .hero-img {
        position: absolute;
        top: 0;
        right: 0;
        width: 48.75rem;
        height: 33.1875rem;
        border-radius: 0.5rem 3.75rem 1.25rem 22.5rem;
        @media only screen and (max-width: 1200px) {
            display: none;
        }
    }
    .hero-animation-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        min-width: 48.75rem;
        height: 33.1875rem;
        @media only screen and (max-width: 1200px) {
            display: none;
        }
    }
    .hero-spacer {
        z-index: 10;
        flex-grow: 1;
        min-width: 48.75rem;
        @media only screen and (max-width: 1200px) {
            display: none;
        }
    }
    .title-wrapper {
        z-index: 10;
        max-width: 41.875rem;
        padding: 0;
        padding-bottom: 4.375rem;
        padding-inline-end: 2.5rem;
        @media only screen and (max-width: 1200px) {
            max-width: none;
            padding: 1rem 0 3.75rem 0;
        }
        // center text
        @media only screen and (max-width: 768px) {
            display: flex;
            flex: 1;
            justify-content: center;
            flex-direction: column;
            text-align: center;
            text-wrap: pretty;
            word-wrap: normal;
        }
        .subtitle {
            margin: 2.5rem 0;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 2.2rem */
            letter-spacing: 0.0138rem;
            color: var(--secondary-color);
        }
        .title {
            margin: 0 0 2rem 0;
            font-size: 2.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 4.4rem */
            letter-spacing: 0.0275rem;
        }
        .text {
            margin: 0;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 180%; /* 2.7rem */
            letter-spacing: 0.015rem;
            // text-align: justify;
            @media only screen and (max-width: 768px) {
                // center text
                text-align: center;
            }
        }
        .get-started-btn {
            cursor: pointer;
            padding: 1rem 2.5rem;
            margin: 3.75rem 0 0.625rem 0;
            border: none;
            text-transform: uppercase;
            border-radius: 2.5rem;
            background: var(--secondary-color);
            color: var(--background-color);
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 700;
            line-height: 140%; /* 1.925rem */
            &:hover {
                background-color: var(--secondary-color-hovered);
                transition: 0.25s background-color ease-in-out;
            }
            @media only screen and (max-width: 768px) {
                margin: 1.25rem 0;
            }
        }
    }
}
body[dir="rtl"] {
    .unlock-potential-section {
        .hero-img {
            transform: scaleX(-1);
            right: auto;
            left: 0;
        }
        .hero-animation-wrapper {
            right: auto;
            left: 0;
        }
    }
}
.potential-items-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0 5rem 0;
    background-color: var(--background-alt-color-1);
    @media only screen and (max-width: 768px) {
        //padding: 2rem 0.5rem 2.5rem 0.5rem;
        padding: 2rem 0.5rem 10.6668rem 0.5rem;
    }
    .screen-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            justify-content: center;
        }
    }
    .item {
        max-width: 30rem;
        @media only screen and (max-width: 768px) {
            max-width: none;
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 7rem;
        }
        svg {
            height: 4.375rem;
            width: 4.375rem;
        }
        .title {
            margin: 2rem 0 0 0;
            font-size: 1.625rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 2.6rem */
            letter-spacing: 0.0163rem;
            @media only screen and (max-width: 768px) {
                margin-top: 1.5rem;
                margin-bottom: 1.5rem;
                text-align: center;
            }
        }
        .subtitle {
            margin: 1.25rem 0;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.6875rem */
            letter-spacing: 0.0112rem;
            @media only screen and (max-width: 768px) {
                margin-top: 0.25rem;
                text-align: center;
            }
        }
    }
}
.steps-to-vehicles {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0 6.25rem 0;
    background-color: var(--background-alt-color-1);
    @media only screen and (max-width: 768px) {
        //padding: 2rem 0.5rem 3.125rem 0.5rem;
        padding: 0rem 0.5rem 16.6668rem 0.5rem;
    }
    .screen-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    .title-wrapper {
        max-width: 25rem;
        @media only screen and (max-width: 768px) {
            max-width: none;
        }
        h3 {
            display: flex;
            flex-direction: column;
            font-size: 2.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 120%; /* 3.3rem */
            letter-spacing: 0.0275rem;
            margin: auto;
            span {
                display: inline-block;
            }
            .colored {
                color: var(--secondary-color);
            }
            @media only screen and (max-width: 768px) {
                align-items: center;
                text-align: center;
            }
        }
        .subtitle {
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 1.6875rem */
            letter-spacing: 0.0112rem;
            margin: 0.75rem 0 0 0;
        }
    }
    .hide-in-mobile {
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .show-in-mobile {
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
    .get-started-btn {
        cursor: pointer;
        padding: 1rem 2.5rem;
        margin: 5rem 0 0.625rem 0;
        border: none;
        text-transform: uppercase;
        border-radius: 2.5rem;
        background: var(--secondary-color);
        color: var(--background-color);
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 1.925rem */
        &:hover {
            background-color: var(--secondary-color-hovered);
            transition: 0.25s background-color ease-in-out;
        }
        @media only screen and (max-width: 768px) {
            margin: 1.25rem 0;
        }
    }
    .steps-wrapper {
        max-width: min(100%, 62.5rem);
        margin-inline-start: auto;
        flex-grow: 1;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        @media only screen and (max-width: 925px) {
            margin-inline-end: auto;
        }
        @media only screen and (max-width: 768px) {
            margin-inline-start: 0;
            margin-inline-end: 0;
            flex-direction: column;
            margin-top: 4rem;
            margin-bottom: 4rem;
        }
        .step {
            padding: 0.9375rem;
            img {
                flex-shrink: 0;
                width: 100%;
                max-width: 9.875rem;
            }
            .text {
                max-width: 9.875rem;
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 1.6875rem */
                letter-spacing: 0.0112rem;
                text-align: center;
                margin: 0;
                color: var(--secondary-color);
            }
        }
    }
}
.choose-vehicles-section {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 2.5rem 0;
    background-color: var(--background-alt-color-1);
    border-radius: 0 0 3.75rem 3.75rem;
    @media only screen and (max-width: 768px) {
        padding: 0 0.5rem 8.3334rem 0.5rem;
        margin-bottom: 16.6668rem;
        border-radius: 0 0 1.75rem 1.75rem;
    }
    .title-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5.02rem;
        @media only screen and (max-width: 768px) {
            margin-bottom: 0rem;
        }
        .title {
            margin: 0;
            font-size: 2.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 4.4rem */
            letter-spacing: 0.0275rem;
            @media only screen and (max-width: 768px) {
                margin: auto;
                text-align: center;
                margin-bottom: 3rem;
            }
        }
        .explore-btn {
            color: var(--secondary-color);
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            text-decoration: none;
            display: inline-flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            svg {
                path {
                    stroke: var(--secondary-color) !important;
                }
                height: 1.5rem;
                width: 1.5rem;
                margin-inline-start: 0.375rem;
            }
            @media only screen and (max-width: 768px) {
                display: none;
            }
        }
    }
    .card-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
        .card {
            cursor: pointer;
            @media only screen and (max-width: 768px) {
                margin-bottom: 2rem;
            }
            img.preview {
                width: 100%;
                min-height: 146px;
                object-fit: contain;
                max-width: 16.25rem;
            }
            .name {
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 400;
                line-height: 160%; /* 2.6rem */
                letter-spacing: 0.0163rem;
                margin: 0;
                text-transform: uppercase;
                @media only screen and (max-width: 768px) {
                    text-align: center;
                }
            }
            .details {
                color: var(--text-alt-color-2);
                font-size: 0.875rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
                margin: 0;
                span {
                    margin-inline-end: 0.375rem;
                    display: inline-block;
                }
                @media only screen and (max-width: 768px) {
                    text-align: center;
                }
            }
            .price {
                .value {
                    color: #000;
                    font-family: "IBM Plex Sans Arabic";
                    font-size: 1.625rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: normal;
                }
                color: var(--text-alt-color-2);
                font-family: "IBM Plex Sans Arabic";
                font-size: 1rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                @media only screen and (max-width: 768px) {
                    text-align: center;
                }
            }
        }
    }
    .show-in-mobile {
        display: none;
        @media only screen and (max-width: 768px) {
            display: block;
        }
    }
    .view-all-btn {
        cursor: pointer;
        padding: 1rem 2.5rem;
        margin: 5rem auto 0.625rem;
        border: none;
        text-transform: uppercase;
        border-radius: 2.5rem;
        background: var(--secondary-color);
        color: var(--background-color);
        font-size: 1.375rem;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 1.925rem */
        display: none;
        text-decoration: none;
        &:hover {
            background-color: var(--secondary-color-hovered);
            transition: 0.25s background-color ease-in-out;
        }
        @media only screen and (max-width: 768px) {
            display: block;
            margin: 2rem 0;
        }
    }
}
body[dir="rtl"] {
    .choose-vehicles-section {
        .explore-btn {
            svg {
                transform: scaleX(-1);
            }
        }
    }
}
.individual-offers-section {
    background-color: var(--background-color);
    position: relative;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
        padding: 0 0.5rem;
        // center text
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        text-wrap: pretty;
        word-wrap: normal;
    }
    .screen-wrapper {
        padding-top: 10rem;
        padding-bottom: 14rem;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            /*
            padding-top: 4.75rem;
            padding-bottom: 4.75rem;
            */
            padding-top: 0rem;
            padding-bottom: 16.6668rem;
            border-radius: 1.75rem;
        }
    }
    .section-bg-3 {
        position: absolute;
        left: -17rem;
        bottom: 9.75rem;
        max-width: min(100%, 29rem);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .section-bg-4 {
        position: absolute;
        left: 31%;
        bottom: 1.875rem;
        max-width: min(100%, 36rem);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .section-bg-5 {
        position: absolute;
        right: -10rem;
        bottom: 0;
        max-width: 100%;
        max-width: min(100%, 34rem);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .cards-wrapper {
        flex-grow: 0;
        z-index: 10;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        gap: 1.5rem;
        list-style: none;
        max-width: min(100%, 59rem);
        margin-inline-start: auto;
        @media only screen and (max-width: 1200px) {
            margin-inline-end: auto;
            flex-direction: column;
            align-items: center;
            gap: 4rem;
        }
        li {
            display: flex;
            max-width: min(75vw, 28.75rem);
            @media only screen and (max-width: 520px) {
                max-width: none;
            }
        }
        .card {
            height: calc(100% - 8rem);
            width: calc(100% - 5rem);
            background-color: var(--background-color);
            padding: 4rem 2.5rem;
            border-radius: 1.25rem;
            box-shadow: 0rem 0rem 1.875rem 0rem rgba(161, 161, 161, 0.32);
            position: relative;
            transition: transform 0.4s ease;
            @media only screen and (max-width: 768px) {
                padding: 3.25rem 1.75rem;
                width: calc(100% - 3.5rem);
                height: auto;
            }
            &:hover {
                transform: translateY(-0.75rem);
            }
            .section-bg {
                display: none;
                position: absolute;
                top: -6rem;
                right: -1.25rem;
                z-index: 15;
                @media only screen and (max-width: 768px) {
                    display: block;
                }
            }
            svg {
                height: 4.375rem;
                width: 4.375rem;
            }
            .title {
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 700;
                line-height: 160%; /* 2.6rem */
                letter-spacing: 0.0163rem;
                margin: 1.875rem 0rem;
                @media only screen and (max-width: 768px) {
                    margin: 1.25rem 0;
                }
            }
            .details {
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 1.6875rem */
                letter-spacing: 0.0112rem;
                margin: 0 0 1.875rem 0;
                @media only screen and (max-width: 768px) {
                    margin: 0 0 1.25rem 0;
                }
            }
            .explore-btn {
                color: var(--secondary-color);
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-decoration: none;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                svg {
                    path {
                        stroke: var(--secondary-color) !important;
                    }
                    height: 1.5rem;
                    width: 1.5rem;
                    margin-inline-start: 0.375rem;
                }
            }
        }
    }
    .title-wrapper {
        z-index: 10;
        flex-grow: 0;
        padding: 0;
        max-width: 33.125rem;
        @media only screen and (max-width: 1200px) {
            padding-inline-end: 1rem;
            max-width: 28.125rem;
        }
        @media only screen and (max-width: 768px) {
            padding-inline-end: 0;
            margin-bottom: 6rem;
            max-width: none;
        }
        .title {
            font-size: 2.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 4.4rem */
            letter-spacing: 0.0275rem;
            max-width: 26.875rem;
            margin: 0 0 2rem 0;
            @media only screen and (max-width: 768px) {
                max-width: none;
            }
        }
        .details {
            // text-align: justify;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 180%; /* 2.7rem */
            letter-spacing: 0.015rem;
            margin: 0;
            @media only screen and (max-width: 768px) {
                max-width: none;
                // center text
                text-align: center;
                text-wrap: pretty;
                word-wrap: normal;
            }
        }
    }
}
body[dir="rtl"] {
    .section-bg-3 {
        transform: scaleX(-1);
        left: auto;
        right: -17rem;
    }
    .section-bg-4 {
        transform: scaleX(-1);
        left: auto;
        right: 31%;
    }
    .section-bg-5 {
        transform: scaleX(-1);
        right: auto;
        left: -10rem;
    }
    .individual-offers-section {
        .cards-wrapper {
            .section-bg {
                left: -1.25rem;
                right: auto;
                transform: scaleX(-1);
            }
        }
        .explore-btn {
            svg {
                transform: scaleX(-1);
            }
        }
        .details {
            // text-align: justify;
            @media only screen and (max-width: 768px) {
                // center text
                text-align: center;
                text-wrap: pretty;
                word-wrap: normal;
                text-align: center;
            }
        }
    }
}
.business-offers-section {
    position: relative;
    background-color: var(--background-alt-color-1);
    position: relative;
    border-radius: 3rem;
    overflow: hidden;
    @media only screen and (max-width: 768px) {
        padding: 0 0.5rem;
        margin-bottom: 16.6668rem;
        // center text
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        text-wrap: pretty;
        word-wrap: normal;
    }
    .section-bg-1 {
        position: absolute;
        left: -50.5rem;
        bottom: 0rem;
        max-width: min(100%, 80rem);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .section-bg-2 {
        position: absolute;
        right: -8rem;
        bottom: -6rem;
        max-width: min(100%, 60rem);
        @media only screen and (max-width: 768px) {
            display: none;
        }
    }
    .screen-wrapper {
        padding-top: 14rem;
        padding-bottom: 10rem;
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            padding-top: 4.75rem;
            padding-bottom: 4.75rem;
            border-radius: 1.75rem;
        }
    }
    .cards-wrapper {
        flex-grow: 1;
        z-index: 10;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        padding: 0;
        gap: 1.5rem;
        list-style: none;
        max-width: min(100%, 59rem);
        margin-inline-end: auto;
        @media only screen and (max-width: 1200px) {
            margin-inline-start: auto;
            flex-direction: column;
            align-items: center;
            gap: 4rem;
        }
        @media only screen and (max-width: 768px) {
            max-width: none;
        }
        li {
            display: flex;
            max-width: min(75vw, 28.75rem);
            @media only screen and (max-width: 520px) {
                max-width: none;
            }
        }
        .card {
            height: calc(100% - 8rem);
            width: calc(100% - 5rem);
            background-color: var(--background-color);
            padding: 4rem 2.5rem;
            border-radius: 1.25rem;
            box-shadow: 0rem 0rem 1.875rem 0rem rgba(161, 161, 161, 0.22);
            position: relative;
            transition: transform 0.4s ease;
            @media only screen and (max-width: 768px) {
                padding: 3.25rem 1.75rem;
                width: calc(100% - 3.5rem);
                height: auto;
            }
            &:hover {
                transform: translateY(-0.75rem);
            }
            .section-bg {
                display: none;
                position: absolute;
                top: -6.25rem;
                right: -1.25rem;
                z-index: 15;
                @media only screen and (max-width: 768px) {
                    display: block;
                }
            }
            svg {
                height: 4.375rem;
                width: 4.375rem;
            }
            .title {
                font-size: 1.625rem;
                font-style: normal;
                font-weight: 700;
                line-height: 160%; /* 2.6rem */
                letter-spacing: 0.0163rem;
                margin: 1.875rem 0rem;
                @media only screen and (max-width: 768px) {
                    margin: 1.25rem 0;
                }
            }
            .details {
                font-size: 1.125rem;
                font-style: normal;
                font-weight: 400;
                line-height: 150%; /* 1.6875rem */
                letter-spacing: 0.0112rem;
                margin: 0 0 1.875rem 0;
                @media only screen and (max-width: 768px) {
                    margin: 0 0 1.25rem 0;
                }
            }
            .explore-btn {
                color: var(--secondary-color) !important;
                font-size: 1.25rem;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
                text-decoration: none;
                display: inline-flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                svg {
                    path {
                        stroke: var(--secondary-color) !important;
                    }
                    height: 1.5rem;
                    width: 1.5rem;
                    margin-inline-start: 0.375rem;
                }
            }
        }
    }
    .title-wrapper {
        z-index: 10;
        flex-grow: 0;
        padding: 0;
        max-width: 33.125rem;
        @media only screen and (max-width: 1200px) {
            padding-inline-start: 1rem;
            max-width: 28.125rem;
        }
        @media only screen and (max-width: 768px) {
            padding-inline-start: 0;
            margin-bottom: 6rem;
            max-width: none;
        }
        .title {
            font-size: 2.75rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 4.4rem */
            letter-spacing: 0.0275rem;
            max-width: 26.875rem;
            margin: 0 0 2rem 0;
            @media only screen and (max-width: 768px) {
                max-width: none;
            }
        }
        .details {
            // text-align: justify;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 400;
            line-height: 180%; /* 2.7rem */
            letter-spacing: 0.015rem;
            margin: 0;
            @media only screen and (max-width: 768px) {
                max-width: none;
                // center text
                display: flex;
                flex: 1;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                text-wrap: pretty;
                word-wrap: normal;
            }
        }
    }
}
body[dir="rtl"] {
    .section-bg-1 {
        transform: scaleX(-1);
        left: auto;
        right: -50.5rem;
    }
    .section-bg-2 {
        transform: scaleX(-1);
        right: auto;
        left: -8rem;
    }
    .business-offers-section {
        .cards-wrapper {
            .section-bg {
                left: -1.25rem;
                right: auto;
                transform: scaleX(-1);
            }
        }
        .explore-btn {
            svg {
                transform: scaleX(-1);
            }
        }
        .details{
            // text-align: justify;
            @media only screen and (max-width: 768px) {
                // center text
                display: flex;
                flex: 1;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                text-wrap: pretty;
                word-wrap: normal;
            }
        }
    }
}
.carousel-cards-section {
    display: flex;
    flex-direction: column;
    background-color: var(--background-color);
    position: relative;
    padding: 12.1875rem 0;
    @media only screen and (max-width: 768px) {
        flex-direction: column;
        // padding: 4.6875rem 0.5rem;
        padding: 0rem 0.5rem 16.6668rem 0.5rem;
        // center text
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        text-wrap: pretty;
        word-wrap: normal;
    }
    .screen-wrapper {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    .title-wrapper {
        margin-inline-end: 1.5625rem;
        h3 {
            max-width: 31.25rem;
            margin-top: 6.5625rem;
            color: var(--text-color);
            font-size: 4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 6.4rem */
            letter-spacing: 0.04rem;
            text-transform: uppercase;
            @media only screen and (max-width: 768px) {
                margin: 0rem;
                width: auto;
            }
        }
        @media only screen and (max-width: 768px) {
            flex-direction: column;
            padding: 3.25rem 1.25rem;
            margin-inline-end: 0;
        }
    }
    .mobile-btn-wrap {
        display: flex;
        justify-content: center;
        margin-top: 2rem;
    }
    .carousel-btn {
        cursor: pointer;
        background-color: var(--primary-color);
        border: none;
        border-radius: 50%;
        padding: 0.75rem 0.75rem 0.625rem 0.75rem;
        margin-inline-end: 1.25rem;
        &:hover {
            background-color: var(--primary-color-hovered);
            transition: 0.25s background-color ease-in-out;
        }
        &:disabled {
            background-color: var(--background-alt-color-2);
            transition: 0.25s background-color ease-in-out;
        }
        @media only screen and (max-width: 768px) {
            display: none;
        }
        &.show-in-mobile {
            display: none;
            svg {
                width: 2rem;
                height: 2rem;
            }
            @media only screen and (max-width: 768px) {
                display: block;
            }
        }
    }
    .card-wrapper-spacer {
        min-width: min(calc(100% - 15rem), 64.75rem);
        min-height: 45rem;
        z-index: -1;
        @media only screen and (max-width: 768px) {
            padding: 2rem 0;
        }
    }
    .cards-wrapper {
        flex-grow: 1;
        margin-inline-start: auto;
        max-width: min(calc(100% - 20rem), 64.75rem);
        position: absolute;
        right: 0;
        bottom: 0;
        z-index: 10;
        @media only screen and (max-width: 768px) {
            max-width: 100%;
        }
        .list {
            width: inherit;
            height: inherit;
            display: flex;
            flex-direction: row;
            padding: 0;
            gap: 1.25rem;

            list-style: none;
            overflow-y: hidden;
            overflow-x: scroll;
            scroll-snap-type: x mandatory;

            scrollbar-width: none;
            -ms-overflow-style: none;
            &::-webkit-scrollbar {
                display: none;
            }
            @media only screen and (max-width: 1200px) {
                padding: 0 1.25rem;
            }
        }

        .item {
            flex-shrink: 0;
            scroll-snap-align: start;
            &:last-child {
                margin-inline-end: 3rem;
            }
            @media only screen and (max-width: 768px) {
                scroll-margin-inline-start: 1.25rem;
                &:last-child {
                    margin-inline-end: calc(calc(90vw - min(70vw, 23.75rem)) / 2);
                }
            }
            @media only screen and (max-width: 600px) {
                &:last-child {
                    margin-inline-end: calc(calc(90vw - min(70vw, 23.75rem)) / 4);
                }
            }
        }

        .content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;

            font-family: sans-serif;
            font-size: 4rem;
            font-weight: bold;
        }

        .carousel-card {
            display: flex;
            height: 42.5rem;
            position: relative;
            width: min(70vw, 23.75rem);
            padding: 0rem 3.125rem 0rem 3.125rem;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 3.75rem;
            background: var(--secondary-color);
            -webkit-transform: scale(1) translateZ(0px);
            @media only screen and (max-width: 429px) {
                width: min(65vw, 20.75rem);
            }
            .title {
                color: var(--background-color);
                font-size: 2.1875rem;
                font-style: normal;
                font-weight: 700;
                line-height: 150%; /* 3.2813rem */
                letter-spacing: 0.0219rem;
                margin-top: 0rem;
                margin-bottom: 0rem;
                @media only screen and (max-width: 768px) {
                    font-size: 2rem;
                    // margin-bottom: 5.25rem;
                }
            }
            .review-btn {
                background-color: var(--background-color);
                color: var(--secondary-color);
                text-decoration: none;
                padding: 1rem 3.125rem;
                border-radius: 3.125rem;
                font-size: 1.375rem;
                font-style: normal;
                font-weight: 700;
                line-height: 140%;
                border: none;
                @media only screen and (max-width: 768px) {
                    padding: 0.75rem 2rem;
                    font-size: 1.125rem;
                }
            }
            .sub-card {
                display: flex;
                flex-direction: column;
                align-items: center;
                background-color: var(--background-color);
                border-radius: 3.75rem;
                padding: 2.0625rem 2.125rem;
                // margin: 0 -2.5rem -1.875rem;
                position: absolute;
                bottom: 1rem;
                width: 23.875rem;
                @media only screen and (max-width: 768px) {
                    padding: 1.5625rem;
                }
                @media only screen and (max-width: 429px) {
                    width: 20.875rem;
                }
                .sub-title {
                    // text-align: justify;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 150%; /* 1.6875rem */
                    letter-spacing: 0.0112rem;
                    margin-top: 0;
                    margin-bottom: 0;
                    @media only screen and (max-width: 768px) {
                        padding: 0.5rem 1.3125rem;
                    }
                }
                .watch-btn {
                    cursor: pointer;
                    background-color: var(--primary-color);
                    color: var(--background-color);
                    border: none;
                    font-size: 1.125rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 140%; /* 1.575rem */
                    padding: 1rem 2.625rem;
                    border-radius: 2.625rem;
                    margin: auto;
                    &:hover {
                        background-color: var(--primary-color-hovered);
                        transition: 0.25s background-color ease-in-out;
                    }
                    @media only screen and (max-width: 768px) {
                        padding: 0.75rem 1.75rem;
                    }
                }
            }
        }
    }
}
body[dir="rtl"] {
    .carousel-cards-section {
        .cards-wrapper {
            right: auto;
            left: 0;
        }
        .button-wrapper {
            button {
                transform: scaleX(-1);
            }
        }
    }
}
.download-app-section {
    display: flex;
    flex-direction: column;
    padding: 0;
    padding-top: 1.875rem;
    background-color: var(--background-alt-color-1);
    border-radius: 3.75rem 3.75rem 0 0;
    position: relative;
    @media only screen and (max-width: 768px) {
        //padding: 1.875rem 0rem 0 0rem;
        padding: 0rem 0rem 0 0rem;
        border-radius: 1.75rem;
        // center text
        display: flex;
        flex: 1;
        justify-content: center;
        flex-direction: column;
        text-align: center;
        text-wrap: pretty;
        word-wrap: normal;
    }
    .screen-wrapper {
        display: flex;
        flex-direction: row;
        line-height: 0;
        @media only screen and (max-width: 768px) {
            flex-direction: column;
        }
    }
    .text-wrapper {
        max-width: 46rem;
        margin-inline-end: 1.875rem;
        @media only screen and (max-width: 768px) {
            margin-inline-end: 0;
            max-width: none;
        }
        .download-app-text-content{
            @media only screen and (max-width: 768px) {
                padding: 0 0.5rem;
            }
        }
        h3 {
            color: var(--text-color);
            font-size: 2rem;
            margin-top: 4rem;
            font-style: normal;
            font-weight: 700;
            line-height: 160%; /* 3.2rem */
            letter-spacing: 0.02rem;
            max-width: 40rem;
        }
        p {
            color: var(--text-alt-color-1);
            // text-align: justify;
            font-size: 1.375rem;
            font-style: normal;
            font-weight: 400;
            line-height: 180%; /* 2.475rem */
            letter-spacing: 0.0138rem;
            margin-bottom: 6rem;
            @media only screen and (max-width: 768px) {
                margin-bottom: 0rem;
                // center text
                display: flex;
                flex: 1;
                justify-content: center;
                flex-direction: column;
                text-align: center;
                text-wrap: pretty;
                word-wrap: normal;
            }
        }
        a {
            text-decoration: none;
            margin-inline-end: 1.125rem;
        }
    }
    .button-wrapper {
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width: 768px) {
            align-items: center;
            justify-content: center;
            margin: 4rem 0 5rem 0;
        }
        img {
            max-width: 100%;
        }
    }
    .picture-wrapper {
        display: flex;
        flex-direction: row;
        margin-inline-start: auto;
        max-width: 25.9375rem;
        max-height: 33.25rem;
        line-height: 0;
        overflow: hidden;
        @media only screen and (max-width: 768px) {
            margin-top: 2rem;
            margin-inline-end: auto;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: contain;
        }
    }
    &.asPage{
        border-radius: 0;
        margin-top: 15vh;
        @media only screen and (max-width: 768px) {
            margin-top: 0;
        }
    }
}

