.announcement-main-container{
    min-height: 100%;
    background: #fff;
    font-family: "Noto Sans", sans-serif !important;
    padding: 10rem 8.333rem 33.25rem 8.333rem;
    @media only screen and (max-width: 768px) {
        padding: 2rem 1.667rem 5.71667rem 1.667rem;
        border-bottom: 1px solid rgba(246, 246, 246, 1);
    }
}
.intro-section{
    .published-date-container{
        margin-bottom: 15.16666rem;
    }
    .clockIcon{
        height: 48px;
        width: 48px;
        object-fit: cover;
        overflow: hidden;
        margin-top: -15px;
    }
    .published-date{
        font-size: 30px;
        font-weight: 400;
        line-height: 40.86px;
        color: rgba(0, 0, 0, 0.4);
        padding-inline-start: 5px;
    }
    .title{
        font-size: 70px;
        font-weight: 600;
        line-height: 95.34px;
        margin-bottom: 2.5rem;
    }
    .intro-image{
        margin-bottom: 3.5834rem;
    }
    .subtitle{
        font-size: 70px;
        font-weight: 600;
        line-height: 90px;
        .highlighted{
            display: inline-block;
            height: 90px;
            line-height: 90px;
            background: linear-gradient(0deg, #FFE39E, #FFE39E),
            linear-gradient(0deg, rgba(255, 227, 158, 0.8), rgba(255, 227, 158, 0.8)),
            linear-gradient(0deg, rgba(255, 227, 158, 0.6), rgba(255, 227, 158, 0.6)),
            linear-gradient(0deg, rgba(255, 227, 158, 0.4), rgba(255, 227, 158, 0.4)),
            linear-gradient(0deg, rgba(255, 227, 158, 0.2), rgba(255, 227, 158, 0.2));
        }
    }
    @media only screen and (max-width: 768px) {
        .published-date-container{
            margin-bottom: 2rem;
        }
        .clockIcon{
            height: 24px;
            width: 24px;
            margin-top: -3px;
        }
        .published-date{
            font-size: 1.1rem;
            line-height: 14.98px;
            padding-inline-start: 4px;
        }
        .title{
            font-size: 1.7rem;
            line-height: 2.25rem;
            margin: 0;
            margin-bottom: 1rem;
        }
        .intro-image{
            margin-bottom: 3.3334rem;
        }
        .subtitle{
            font-size: 1.4rem;
            line-height: 2.2rem;
            margin-bottom: 1.5rem;
            .highlighted{
                display: inline;
                font-size: 1.25rem;
                line-height: 2.083rem;
            }
        }
    }
}
body[dir="rtl"] {
    .intro-section{
        .highlighted{
            padding: 0 2px;
        }
    }
}
.paragraphs-section{
    .paragraph{
        font-weight: 400;
        font-size: 30px;
        line-height: 60px;
        color: rgba(0, 0, 0, 0.8);
        margin-top: 0;
        margin-bottom: 1.5rem;
    }
    @media only screen and (max-width: 768px) {
        .paragraph{
            font-size: 1.4rem;
            line-height: 2.5rem;
            margin-top: 0;
            margin-bottom: 3.75rem;
        }
    }
        
}
