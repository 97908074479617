:root {
    --primary-color: #ff9800;
    --secondary-color: #451a6b;
    --primary-color-hovered: #d58000;
    --secondary-color-hovered: #200c31;
    --secondary-color-lighten: #ac3eb5;
    --text-color: #000000;
    --text-alt-color-1: #4b4b4b;
    --text-alt-color-2: #616161;
    --background-color: #ffffff;
    --background-alt-color-1: #f6f6f6;
    --background-alt-color-2: #f8f9fd;
    --divider-color: #d4d4d4;
    --font-family: "Noto Sans Arabic", "Noto Sans", "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
        "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
}

html {
    font-size: 12px;
}

body {
    margin: 0;
    font-family: var(--font-family);
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.screen-wrapper {
    z-index: 10;
    margin: 0 auto;
    padding: 0 2.5rem;
    width: calc(min(100%, 108.5rem) - 5rem);
    @media only screen and (max-width: 768px) {
        width: calc(100% - 2.5rem);
        padding: 0 1.25rem;
    }
}
.floating-img-wrapper {
    position: relative;
    width: 100%;
    max-width: min(100%, 130rem);
    margin: 0 auto;
}
@media only screen and (max-width: 768px) {
    .hide-sm{
        display: none !important;
    }
}